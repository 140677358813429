import React from 'react'

import Markdown from 'components/Markdown'
import Sidebar from 'components/Sidebar'
import { Container } from 'components/System'

const Component = ({ title, children, lastUpdatedAt }) => (
  <div className="py-8 md:py-16 lg:py-24">
    <div className="mb-12">
      <Container>
        <h1 className="h1">{title}</h1>
      </Container>
    </div>
    <Container>
      <div className="lg:flex -m-4">
        <div className="p-4 w-full lg:w-7/12">
          <div className="space-y-4">
            <Markdown>{children}</Markdown>

            {lastUpdatedAt && (
              <p className="h6">Zuletzt aktualisiert &mdash; {lastUpdatedAt}</p>
            )}
          </div>
        </div>

        <div className="p-4 w-full lg:w-5/12 xl:w-4/12 xl:ml-auto">
          <Sidebar />
        </div>
      </div>
    </Container>
  </div>
)

export default Component
