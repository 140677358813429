import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Information zur Datenverarbeitung nach Artikel 13 und 14 Datenschutzgrundverordnung (DSGVO)`}</h2>
    <p>{`Wir informieren Sie hiermit über die Verarbeitung Ihrer personenbezogenen Daten und die Ihnen zustehenden datenschutzrechtlichen Ansprüche und Rechte. Der Inhalt und Umfang der Datenverarbeitung richtet sich maßgeblich nach den jeweils von Ihnen beantragten bzw. mit Ihnen vereinbarten Produkten und Dienstleistungen.`}</p>
    <h2>{`Wer ist für die Datenverarbeitung verantwortlich und an wen können Sie sich wenden?`}</h2>
    <p>{`Verantwortlicher für die Datenverarbeitung:`}<br />{`
DIE ANTWORT · Büro für Informationstechnik GmbH`}</p>
    <p>{`DIE ANTWORT · Büro für Informationstechnik GmbH`}<br />{`
Obere Weißgerberstraße 4, 1030 Wien`}<br />{`
FN 315384a · HG Wien`}<br /></p>
    <p>{`Tel.: +43 (1) 997 16 20 - 0`}<br />{`
E-Mail: `}<a parentName="p" {...{
        "href": "mailto:team@die-antwort.eu"
      }}>{`team@die-antwort.eu`}</a></p>
    <h2>{`Welche Daten werden verarbeitet und aus welchen Quellen stammen diese?`}</h2>
    <p>{`Wir verarbeiten jene personenbezogenen Daten, die wir von Ihnen im Rahmen unserer Geschäftsbeziehung erhalten.`}</p>
    <p>{`Zu den personenbezogenen Daten zählen Ihre persönlichen Detailangaben und Kontaktdaten (z.B. Name, Adresse, Geburtstag und -ort, Staatsangehörigkeit, E-Mail-Adressen etc.) oder Daten zu Identitätsdokumenten (z.B. Ausweisdaten). Darüber hinaus können darunter auch Zahlungsverkehrsdaten (z.B. Zahlungsaufträge), Bonitätsdaten (z.B. auf Basis der Jahresabschlüsse) Daten zu Marketing und Vertrieb, Bild- und/oder Tonaufzeichnungen (z.B. Fotos, Video-, Telefonaufzeichnungen), elektronische Protokoll- und Identifikationsdaten (Apps, Cookies etc.), sowie andere, mit den genannten Kategorien vergleichbare Daten fallen.`}</p>
    <p>{`Zudem verarbeiten wir jene Kategorie von Daten, die wir aus öffentlich zugänglichen Quellen (z.B. Firmenbuch, Vereinsregister, Grundbuch oder Medien) zulässigerweise erhalten haben oder die uns von der DIE ANTWORT · Büro für Informationstechnik GmbH und verbundenen Unternehmen übermittelt werden. Darunter fallen insbesondere Firmenwortlaut, Firmenanschrift, Firmenbuchnummer sowie Daten von Verfügungs- bzw. zeichnungsberechtigten Personen (wie etwa Anrede/Geschlecht, Name, Anschrift, Kontaktdaten).`}</p>
    <h2>{`Für welche Zwecke und auf welcher Rechtsgrundlage werden die Daten verarbeitet?`}</h2>
    <p>{`Wir verarbeiten Ihre personenbezogenen Daten im Einklang mit den Bestimmungen der Europäischen Datenschutzgrundverordnung (DSGVO) und dem Datenschutzgesetz 2018.`}</p>
    <p><strong parentName="p">{`wenn Sie in die Verarbeitung im konkreten Einzelfall eingewilligt haben (Art. 6 Abs. 1 lit a DSGVO)`}</strong></p>
    <p>{`Die Verarbeitung Ihrer personenbezogenen Daten (Art. 4 Nr. 2 DSGVO) erfolgt auf Basis Ihrer Einwilligung nur für konkrete Zwecke, für die Sie sich freiwillig in informierter Weise einverstanden erklärt haben. In einem solchen Fall werden wir Sie gesondert unter Darlegung aller wesentlichen Umstände auf Sie zugehen, sodass Sie uns Ihre Einwilligung zu konkreten Verarbeitungsvorgängen geben können.`}</p>
    <p><strong parentName="p">{`zur Erfüllung vertraglicher Pflichten (Art. 6 Abs. 1 lit b DSGVO)`}</strong></p>
    <p>{`Die Verarbeitung personenbezogener Daten erfolgt zur Erbringung und Vermittlung von Beratungs- und Finanzdienstleistungen, Erarbeitung von Organisationskonzepten, im Rahmen von Verwaltungstätigkeiten und insbesondere zur Durchführung unserer Verträge mit Ihnen und zur Durchführung vertraglicher Maßnahmen.`}</p>
    <p><strong parentName="p">{`zur Erfüllung rechtlicher Verpflichtungen (Art. 6 Abs. 1 lit c DSGVO)`}</strong></p>
    <p>{`Die Verarbeitung personenbezogener Daten kann zum Zweck der Erfüllung unterschiedlicher gesetzlicher Verpflichtungen sowie aufsichtsrechtlicher Vorgaben erforderlich sein.`}</p>
    <h2>{`Wer erhält meine Daten?`}</h2>
    <p>{`Innerhalb der DIE ANTWORT · Büro für Informationstechnik GmbH erhalten jene Stellen bzw. MitarbeiterInnen Ihre Daten, die diese zur Erfüllung vertraglicher, gesetzlicher und/oder aufsichtsrechtlicher Pflichten sowie berechtigter Interessen benötigen.`}</p>
    <h2>{`Wie lange werden meine Daten gespeichert?`}</h2>
    <p>{`Wir verarbeiten Ihre personenbezogenen Daten, soweit erforderlich, für die Dauer der gesamten Geschäftsbeziehung (von der Anbahnung, Abwicklung bis zur Beendigung eines Vertrags) sowie darüber hinaus gemäß den gesetzlichen Aufbewahrungs- und Dokumentationspflichten, die sich u.a. aus dem Unternehmensgesetzbuch (UGB), der Bundesabgabenordnung (BAO), usw. ergeben.`}</p>
    <h2>{`Welche Datenschutzrechte stehen mir zu?`}</h2>
    <p>{`Sie haben das Recht auf Auskunft, Berichtigung, Löschung oder Einschränkung der Verarbeitung Ihrer gespeicherten Daten, ein Widerspruchsrecht gegen die Verarbeitung sowie ein Recht auf Datenübertragbarkeit gemäß den Voraussetzungen der DSGVO.`}</p>
    <p>{`Beruht die Verarbeitung Ihrer personenbezogenen Daten auf Ihrer Einwilligung, können Sie diese jederzeit ohne Angaben von Gründen widerrufen, ohne dass die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung berührt wird.`}</p>
    <p>{`Beschwerden aufgrund der Verarbeitung Ihrer personenbezogenen Daten können an die Österreichische Datenschutzbehörde, Barichgasse 40-42, 1030 Wien, Österreich, `}<a parentName="p" {...{
        "href": "http://www.dsb.gv.at/"
      }}>{`www.dsb.gv.at`}</a>{`, gerichtet werden.`}</p>
    <h2>{`Bin ich zur Bereitstellung von Daten verpflichtet?`}</h2>
    <p>{`Im Rahmen der Geschäftsbeziehung müssen Sie diejenigen personenbezogenen Daten bereitstellen, die für die Aufnahme und Durchführung der Geschäftsbeziehung erforderlich sind und zu deren Erhebung wir gesetzlich verpflichtet sind. Wenn Sie uns diese Daten nicht zur Verfügung stellen, werden wir den Abschluss des Vertrags oder die Ausführung des Auftrags in der Regel ablehnen oder einen bestehenden Vertrag nicht mehr durchführen können und somit beenden müssen. Sie sind jedoch nicht verpflichtet, hinsichtlich für die Vertragserfüllung nicht relevanter bzw. gesetzlich oder regulatorisch nicht erforderlicher Daten eine Einwilligung zur Datenverarbeitung zu erteilen.`}</p>
    <h2>{`Gibt es eine automatisierte Entscheidungsfindung?`}</h2>
    <p>{`Zur Begründung und Durchführung der Geschäftsbeziehung nutzen wir grundsätzlich keine vollautomatisierte Entscheidungsfindung nach Artikel 22 DSGVO.`}</p>
    <h2>{`Cookies`}</h2>
    <p>{`Unsere Webseite verwendet nur technisch notwendige Cookies.`}</p>
    <p>{`Technische notwendige Cookies sind kleine Dateien, die für die grundlegenden Funktionen der Website notwendig sind. Sie dienen dazu, dass die Website ordnungsgemäß angezeigt wird, technisch funktioniert und die jeweiligen Ebenen richtig miteinander kommunizieren.
Diese Cookies können Sie blockieren oder löschen, aber Sie laufen dann Gefahr, dass einige Teile der Website nicht ordnungsgemäß funktionieren.`}</p>
    <h2>{`Protokollierung am Webserver`}</h2>
    <p>{`Bei jedem Zugriff eines Nutzers auf unsere Webseite sowie bei jedem Abruf oder Versuch eines Abrufes einer Datei auf dem Server, werden Daten über diesen Vorgang in einer Protokolldatei gespeichert (Logfile). Für uns ist nicht unmittelbar nachvollziehbar, welcher Nutzer welche Daten abgerufen hat. Wir versuchen auch nicht, diese Informationen zu erheben. Das wäre nur in gesetzlich geregelten Fällen und mit Hilfe Dritter (z.B. Internet Service Provider) möglich. Im Einzelnen wird über jeden Abruf folgender Datensatz gespeichert: Die IP-Adresse, der Name der abgerufenen Datei, das Datum und die Uhrzeit des Abrufs, die übertragene Datenmenge, die Meldung, ob der Abruf erfolgreich war sowie die Meldung, warum ein Abruf gegebenenfalls fehlgeschlagen ist, der Name Ihres Internet Service Providers, gegebenenfalls das Betriebssystem, die Browsersoftware Ihres Computers sowie die Webseite von der aus Sie uns besuchen.`}</p>
    <p>{`Die rechtliche Grundlage für die Verarbeitung dieser personenbezogenen Daten ist unser berechtigtes Interesse (gemäß Art 6 Abs 1 lit f DSGVO). Dieses besteht darin, Angriffe auf unsere Website erkennen, verhindern und untersuchen zu können.`}</p>
    <p>{`Diese Daten werden nur temporär für die Dauer von maximal 30 Tagen gespeichert.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      